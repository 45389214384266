export const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  
 export const cronToText = (cronExpression) => {
    const [minute, hour, dayOfMonth, month, dayOfWeek] = cronExpression.split(' ');
    
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    // Parse day of week
    let dayOfWeekText = '';
    if (dayOfWeek !== '*') {
        const daysOfWeek = dayOfWeek.split(',');
        const dayNamesArray = daysOfWeek.map(day => dayNames[parseInt(day)]);
        dayOfWeekText = `Every ${dayNamesArray.join(' and ')}`;
    }

    let time = ''
     // Parse minute
     let minuteText = '';
     if (minute !== '*') {
        const minuteFormatted = minute.padStart(2, '0');
        minuteText = `:${minuteFormatted}`;
    }

    // Parse hour
    if (hour !== '*') {
        const hourNum = parseInt(hour);
        const ampm = hourNum < 12 ? 'AM' : 'PM';
        const hourFormatted = hourNum <= 12 ? hourNum : hourNum - 12;
        time = ` at ${hourFormatted}${minuteText} ${ampm}`;
    }

    // Combine parsed parts
    let result = '';
    if (dayOfWeek !== '*' && dayOfMonth === '*' && month === '*') {
        result = `${dayOfWeekText}${time}`;
    } else {
        result = `Every${dayOfMonth === '*' ? '' : ' ' + parseInt(dayOfMonth)}${dayOfMonth === '*' ? '' : 'th'} day${dayOfMonth === '*' ? ' of every month' : ''} ${time}`;
    }

    return result.trim();
};


export const getRatingColor = (index) => {
    const colors = [
        '#FF0000', 
        '#FF3300',
        '#FF6600',
        '#FF9900',
        '#FFCC00',
        '#FFFF00', 
        '#CCFF00',
        '#99FF00',
        '#66FF00',
        '#33FF00',
        '#00FF00'
      ];
    return colors[index];
  };

export const getBAMColor = (index) => {
    const colors = [
        '#FF0000', 
        '#FF6600',
        '#FF9900',
        '#FFCC00',
        '#FFFF00', 
        '#CCFF00',
        '#33FF00',
        '#00FF00'
      ];
    return colors[index];
}

export const getReverseBAMColor = (index) => {
    const colors = [
        '#FF0000', 
        '#FF6600',
        '#FF9900',
        '#FFCC00',
        '#FFFF00', 
        '#CCFF00',
        '#33FF00',
        '#00FF00'
      ];
    const revereColors = colors.reverse()
    return revereColors[index];
}
